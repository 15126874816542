import React, {Component} from "react";

import styles from "./index.module.scss";
import {APP_GLOBAL} from "../App/DataManager";
import {
    BeyBroadcaster,
    BeyonityUiUtils,
    Collapsible,
    Format,
    GoogleMap,
    InfoCard,
    MediaQuery,
    PoiItemCard,
    ProjectItemCard,
    SequencialFadeIn
} from "@beyonityeu/beyonity-ui-buttons";
import * as propTypes from "prop-types";
import {CATEGORY_COMMERCE, CATEGORY_INFO_SPOT, CATEGORY_LIVING, FILTER_SETTINGS_CHANGE_EVENT_TOPIC} from "../FilterBox";
import "./index.css";




/**
 * This component houses the sidebars list of items.
 * It's also responsible for applying filters and sorting it.
 *
 * @author [Willi Boelke](willi-dev@outlook.com)
 */
class ItemCardList extends Component {


    constructor(props) {
        super(props);

        this.mediaQuerry = new MediaQuery();
        this.state = {
            items         : props.items,
            filterSettings: null,
            hoveredItem: null,
            isLandscape: this.mediaQuerry.get("orientation") === "landscape"
        };
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        console.log("ItemCardList.shouldComponentUpdate");
        console.log(!BeyonityUiUtils.compareObjects(nextState.filterSettings, this.state.filterSettings))
        if (!BeyonityUiUtils.compareObjects(nextState.filterSettings, this.state.filterSettings)) {
            console.log("filterSettings changed, filtering items");
            /*
             this will only filter the items, not re-render the component
             it then sets the items in the state which will trigger a re-render.
             */
            this._filterItems(nextState.filterSettings);
            return false;
        }
        if (!BeyonityUiUtils.compareObjects(nextState.items, this.state.items)) {
            return true;
        }
        return true;
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.items !== this.props.items || !BeyonityUiUtils.compareObjects(prevProps.filterSettings, this.props.filterSettings)) {
            this.setState({
                items: this.props.items,
                filterSettings: this.props.filterSettings
            }, () => {
                this._filterItems(this.state.filterSettings);
            });
        }
    }


    componentDidMount() {
        BeyBroadcaster.instance.subscribe(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, this);
        BeyBroadcaster.instance.unsubscribe(MediaQuery.SCREEN_CHANGE_EVENT, this);
        BeyBroadcaster.instance.subscribe(MediaQuery.SCREEN_CHANGE_EVENT, this);
        this._filterItems(this.state.filterSettings);
    }


    componentWillUnmount() {
        BeyBroadcaster.instance.unsubscribe(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, this);
    }


    render() {

        const {
            items,
            isLandscape
        } = this.state;

        // separate the info spots from the rest

        const infoSpots = {};
        const navigators = [];


        items.forEach((item) => {
            if (item.category === CATEGORY_INFO_SPOT) {
                if (!infoSpots[item.infoFlagCategory]) {
                    infoSpots[item.infoFlagCategory] = [];
                }
                infoSpots[item.infoFlagCategory].push(item);
            } else {
                navigators.push(item);
            }
        });

        return (
            <div className={styles.itemCardList}>
                <SequencialFadeIn inBetweenDelay={50} duration={400}>
                    {( navigators && navigators.length > 0 ) ?
                        navigators.map((item, i) => {
                            if (item.category === CATEGORY_INFO_SPOT && !item.showInSidebar) {
                                return null;
                            }
                            return this._renderItem(item, i);
                        })
                        :
                        !APP_GLOBAL.data.settings.hasOnlyPois &&
                        <InfoCard text={"Es konnten leider keine passenden Liegenschaften gefunden werden"}/>
                    }
                    {isLandscape ?
                        Object.keys(infoSpots).map((infoFlagCategory, i) => {
                        return (
                            <Collapsible
                                key={infoFlagCategory}
                                className={`beyonity-po--info-flag-collapsible`}
                                iconName={APP_GLOBAL.data.infoFlagCategories[infoFlagCategory]?.icon}
                                header={<div
                                    className={styles.infoFlagHeader + " beyonity-ui--text__large-highlighted"}>
                                    {APP_GLOBAL.data.infoFlagCategories[infoFlagCategory].name}
                                </div>}
                                elevation={"Neutral"}
                                expanded>
                                <div className={styles.infoSpotList}>
                                    {infoSpots[infoFlagCategory].map((item, i) => {
                                        return this._renderItem(item, i);
                                    })}
                                </div>
                            </Collapsible>
                        )
                        })
                        :
                        Object.keys(infoSpots).map((infoFlagCategory, i) => {
                            return infoSpots[infoFlagCategory].map((item, i) => {
                                    return <div className={styles.infoSpotWidthWrapper}>
                                        {this._renderItem(item, i)}
                                    </div>
                                }
                            )
                        })
                    }
                </SequencialFadeIn>
            </div>
        );
    }


    _renderItem = (item, index = null) => {
        const {hoveredItem} = this.state;
        const {onItemClick, onItemHover} = this.props;
        return ItemCardList.renderProjectItemCard(item, index, hoveredItem, onItemClick, onItemHover, this.state.filterSettings);
    }

    /**
     * This returns a `ProjectItemCard` for the given `item`, which maybe a "Navigator" or a
     * "Configurator", if the item is mapped from an object the index parameter is used as the
     * elements `key`. It can be set to false when no array is used. This will decouple the
     * item from the `activeItem` state and thus prevent it from being highlighted as active.
     * (For the Tooltip)
     * @param item {Object} | {boolean}
     * The item to render
     * @param index {number|boolean}
     * The index of the item in the array or `false`
     * @param hoveredItem
     * @param onItemHover
     * @param onItemClick
     * @param filterSettings
     * @return {JSX.Element}
     */
    static renderProjectItemCard = (item,
                                    index = 0,
                                    hoveredItem = false,
                                    onItemClick,
                                    onItemHover,
                                    filterSettings,
                                    tooltip = false) => {
        const {
            thumbnail,
            dsc,
            propsList,
            icons,
            areaFreeTotal,
        } = item;




        if (item.category === CATEGORY_INFO_SPOT) {
            if (!tooltip && item.showInSidebar === "No") return null;

            return (
                <PoiItemCard name={item.dsc}
                             className={tooltip && "beyonity-po--poi-item-card__tooltip"}
                             key={`item_${index}`}
                             onClick={() => {
                                 onItemClick && onItemClick(item, true);
                             }}
                             onMouseEnter={() => {
                                 onItemHover && onItemHover(item, false)
                             }}
                             onMouseLeave={() => {
                                 onItemHover && onItemHover(false, false)
                             }}
                             active={item.id === hoveredItem}
                             thumbnail={item.thumbnail || undefined}/>
            );
        }




        return (
            <div key={`item_${index}`}>
                <ProjectItemCard imageLink={thumbnail}
                                 infos={""}
                                 name={dsc}
                                 icons={icons}
                                 className={tooltip && "beyonity-po--project-item-card__tooltip"}
                                 badgeContent={item.status}
                                 badgeColor={item.statusColor}
                                 active={item.id === hoveredItem}
                                 properties={[propsList[0], propsList[1]]}
                                 onMouseEnter={() => {
                                     onItemHover && onItemHover(item, false)
                                 }}
                                 onMouseLeave={() => {
                                     onItemHover && onItemHover(false, false)
                                 }}
                                 onClick={() => {
                                     onItemClick && onItemClick(item, true);
                                 }}
                />
            </div>
        );
    }


    //
    // --------- Filtering -----------
    //


    _filterItems = (filterSettings) => {
        if (!filterSettings) return;
        let tmpItems = BeyonityUiUtils.copyObject(APP_GLOBAL.data.items);
        const {location, category, type, sort} = filterSettings;

        //---- Sending location and radius to the GoogleMap component ----//
        if (location.lat !== null && location.lng !== null) {
            BeyBroadcaster.instance.publish(GoogleMap.BROADCAST_MAP_CIRCLE_POSITION_TOPIC,
                {
                    lat   : filterSettings.location.lat,
                    lng   : filterSettings.location.lng,
                    radius: filterSettings.location.radius,
                });
        }



        tmpItems = tmpItems.filter(item => {
            //---- Filtering items by distance ----//
            if (location.lat !== false && location.lat !== null && location.lng !== null) {
                if (BeyonityUiUtils.distanceBetweenCoordinates(
                    filterSettings.location.lat,
                    filterSettings.location.lng,
                    item.location.lat,
                    item.location.lng
                ) > filterSettings.location.radius) return false;
            }


            //--------- Sort out by category -----------//

            if (item.category === CATEGORY_INFO_SPOT) {
                item.statusColor = `rgb(${APP_GLOBAL.data.infoFlagCategories[item.infoFlagCategory].color})`;
            } else {
                // ------ status badge ------ //
                // Status color and content may change depending on the applied filters
                // They are set here to be used in other places in the ap
                let status = APP_GLOBAL.data.settings.status;
                item.status = Format.formatArea(item.areaFreeTotal);
                item.statusColor = status.not_available.color;
                const language = APP_GLOBAL.language;
                if (filterSettings.category === CATEGORY_COMMERCE) {
                    item.status = item.units[filterSettings.category]?.freeArea || 0;
                    if (item.status > 0) {
                        item.statusColor = status.available.color;
                    }
                    item.status = Format.formatArea(item.status);
                    console.log("CATEGORY_COMMERCE item.status", item.status);
                    console.log("CATEGORY_COMMERCE item.statusColor", item.statusColor);
                }
                if (filterSettings.category === CATEGORY_LIVING) {
                    item.status = item.units[filterSettings.category]?.freeUnits;
                    if (item.status > 0) {
                        item.statusColor = status.available.color;
                    }
                    item.status = `${item.status} ${language.general.free}`;
                }


            }

            if (item.category === CATEGORY_INFO_SPOT) {
                if (filterSettings.infoFlagCategory.length === 0) return true;
                // info flags are not filtered by category and properties but by the infoFlagCategory
                if (!filterSettings.infoFlagCategory.includes("all")) {
                    if (!filterSettings.infoFlagCategory.includes(item.infoFlagCategory)) return false;
                }
            } else if (category !== "all") {
                if (!item.availableCategories.includes(category)) return false;
                if (!type.some(typeToFilter => item.availableTypesPerCategory[category].includes(typeToFilter))) return false;
            }

            //--------- category specific -----------//

            if (category.includes(CATEGORY_COMMERCE) && filterSettings.categorySpecificProperties.commerce.area) {
                if (item.category === CATEGORY_INFO_SPOT) return true;
                if (!this.isTypeWithinAreaRange(item, filterSettings)) return false;
            }

            //--------- info flags -----------//


            //--------- Sorting -----------//

            if (filterSettings.propertyFilters && filterSettings.propertyFilters !== {}) {
                if (item.category === CATEGORY_INFO_SPOT) return true;
                const propertyFilter = filterSettings.propertyFilters;
                const itemProperties = item.properties;
                for (let property in propertyFilter) {
                    if (propertyFilter[property].type === "number") {
                        if (itemProperties[property] === "" || itemProperties[property] > propertyFilter[property].max) return false;
                    } else if (propertyFilter[property].type === "options") {
                        if (itemProperties[property] === "" || !propertyFilter[property].options.includes(itemProperties[property])) return false;
                    } else if (propertyFilter[property].type === "boolean") {
                        if (!propertyFilter[property].value !== ( itemProperties[property] === "" )) return false;
                        else if (propertyFilter[property].value === ( itemProperties[property] === "" )) return false;
                    }
                }
            }



            return true;
        });
        this.props.onMarkersChanged(BeyonityUiUtils.copyObject(this._generateMarkerListFromItems(tmpItems, filterSettings)));


        BeyonityUiUtils.deepSort(tmpItems, [[sort.by, sort.asc]]);
        this.setState({
            items: tmpItems
        });
    }


    isTypeWithinAreaRange(item, filterSettings) {
        const selectedCategory = filterSettings.category;
        const selectedTypes = filterSettings.type;
        const areaFreeMinMaxByType = item.areaFreeMinMaxByType;
        const categoryAreaRange = filterSettings.categorySpecificProperties.commerce.area;

        for (const type of selectedTypes) {
            // skip the type "all" since it is not a real type
            if (type === "all") continue;
            let min = 0;
            let max = 0;
            let found = false;
            for (const categoryObj of areaFreeMinMaxByType) {
                if (categoryObj[selectedCategory]) {
                    for (const typeObj of categoryObj[selectedCategory]) {
                        if (typeObj[type]) {
                            min = typeObj[type].min;
                            max = typeObj[type].max;
                            found = true;
                            break;
                        }
                    }
                }
            }
            if (found && ( +min >= +categoryAreaRange.from ) && ( +max <= +categoryAreaRange.to )) {
                return true; // At least one type falls within the area range
            }
        }
        return false; // No selected type falls within the area range
    }

    _generateMarkerListFromItems = (items, filterSettings) => {
        const {
            onItemClick,
            onItemHover
        } = this.props;

        let result = [];
        items.forEach(item => {
            result.push({
                lat       : +item.location.lat,
                lng       : +item.location.lng,
                id        : item.id,
                name      : item.dsc,
                category  : item.category,
                icon      : item.pin,
                pathId    : item.pathId,
                paths     : item.paths,
                statusColor: item.statusColor,
                activeIcon: item.activePin,
                primaryFacade: item.primaryFacade,
                onClick     : () => {
                    onItemClick(item, false);
                },
                onMouseEnter: () => {
                    this.setState({
                        hoveredItem: item.id
                    }, () => {
                        onItemHover(item, true)
                    });
                },
                onMouseLeave: () => {
                    this.setState({
                        hoveredItem: null
                    })
                    onItemHover(false, false);
                },
            });
        });
        return result;
    }


    onReceive = (topic, event) => {
        switch (topic) {
            case MediaQuery.SCREEN_CHANGE_EVENT :
                this.setState({isLandscape: event.orientation === "landscape"});
                break;
            case FILTER_SETTINGS_CHANGE_EVENT_TOPIC:
                this.setState({
                    filterSettings: BeyonityUiUtils.copyObject(event)
                });
        }
    }

}

ItemCardList.defaultProps = {};

ItemCardList.propTypes = {
    items      : propTypes.arrayOf(propTypes.object),
    filterSettings: propTypes.object,
    onItemClick: propTypes.func,
    onItemHover: propTypes.func,
};

export default ItemCardList;